import styled from "styled-components";

interface EmojiTextAreaStyled {
  $isDarkMode: boolean;
}

export const AreaContainer = styled.div<EmojiTextAreaStyled>`
  border-radius: 0.5em;
  padding: 6px;
  margin: 12px 6px;
  box-shadow: ${({ $isDarkMode }) =>
    $isDarkMode
      ? "0px 0px 4px white"
      : `0px 3px 3px -2px rgba(0, 0, 0, 0.2),
       0px 3px 4px 0px rgba(0, 0, 0, 0.14),
       0px 1px 8px 0px rgba(0, 0, 0, 0.12)`};
`;

export const EmojiButton = styled.button`
  background: transparent;
  border: 0;
  &:hover {
    cursor: pointer;
  }
`;

export const Description = styled.textarea<
  { $isMobile: boolean } & EmojiTextAreaStyled
>`
  ${({ $isDarkMode }) =>
    $isDarkMode
      ? `
  color: white;
  background-color: var(--dark-mode-custom-background);
`
      : `
  color: black;
  background-color: white;
`};
  resize: none;
  border: none;
  outline: none;
  width: 230px;
  height: 86px;
  border-radius: 0.5em;
  padding: 8px;
  font-size: 14px
  &:focus {
    outline: none;
  }
`;

export const Characters = styled.div<EmojiTextAreaStyled>`
  opacity: 0.5;
  text-align: end;
  color: ${({ $isDarkMode }) => ($isDarkMode ? "white" : "black")};
`;

export const CharEmojiButton = styled.button<EmojiTextAreaStyled>`
  ${({ $isDarkMode }) =>
    $isDarkMode
      ? `
color: white;
`
      : `
color: black;
`};
  background: transparent;
  border: 0;
  &:hover {
    cursor: pointer;
  }
  font-size: medium !important;
`;
