import { Autocomplete, TextField, useTheme } from "@mui/material";
import { CompanyData } from "../../models/Table";
import "./SearchBar.scss";

interface SearchBarProps {
  options: CompanyData[];
  //   defaultValue: { title: string }[];
  placeholder: string;
  isMobile: boolean;
}

const SearchBarComponent: React.FC<SearchBarProps> = ({
  options,
  placeholder,
  isMobile,
}) => {
  const theme = useTheme();
  return (
    <Autocomplete
      multiple
      limitTags={1}
      size="small"
      sx={{ width: !!isMobile ? "50%" : "20%", height: "fit-content" }}
      className={`multiple-limit-tags ${
        theme.palette.mode === "light" ? "" : "dark-mode"
      }`}
      options={options}
      getOptionLabel={(option) => option.company || option.email}
      //   defaultValue={defaultValue}
      renderInput={(params) => (
        <TextField {...params} placeholder={placeholder} />
      )}
    />
  );
};

export default SearchBarComponent;
