const envConfig = {
  isDevelopment: () => process.env.REACT_APP_ENV === "development",
  isProduction: () => process.env.REACT_APP_ENV === "production",

  apiUrl:
    process.env.REACT_APP_ENV === "development"
      ? process.env.REACT_APP_URL_DEV
      : process.env.REACT_APP_URL_PROD,
};

export default envConfig;
