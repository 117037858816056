import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { User } from "../models/Auth-site";

export type Authorized = "Authorized" | "unAuthorized";

interface SessionContextType {
  isAuthorized: Authorized | undefined;
  user: User;
  loading: boolean;
  setUser: (user: User) => void;
  setSession: (status: Authorized) => void;
  hasPermission: (permission: string) => boolean;
  initUser: (user: User) => void;
  logOut: () => void;
  setLoading: (load: boolean) => void;
}

const userObj = {
  email: "",
  user_name: "",
  role_title: "",
  permissions: [],
};

// context
const SessionContext = createContext<SessionContextType>({
  isAuthorized: undefined,
  user: userObj,
  loading: false,
  setUser: (user: User) => {},
  setSession: (status: Authorized) => {},
  hasPermission: (permission: string) => false,
  initUser: (user: User) => {},
  logOut: () => {},
  setLoading: (load: boolean) => {},
});

//provider
export const SessionProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User>(() => {
    const userLStorage = localStorage.getItem("dirigent_user");
    return userLStorage ? JSON.parse(userLStorage) : userObj;
  });
  const [isAuthorized, setIsAuthorized] = useState<Authorized>();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const setSession = (status: Authorized) => {
    setIsAuthorized(status);
    localStorage.setItem("isAuthorized", status);
  };

  const initUser = (userReq: User) => {
    setUser(userReq);
    localStorage.setItem("dirigent_user", JSON.stringify(userReq));
    localStorage.setItem(
      "expire_login",
      (new Date().getTime() + 8 * 60 * 60 * 1000).toString()
    );
  };

  const logOut = () => {
    localStorage.removeItem("dirigent_user");
    localStorage.removeItem("isAuthorized");
    setIsAuthorized("unAuthorized");
  };

  const hasPermission = (permission: string) => {
    return user.permissions.some((v) => v === permission);
  };

  const isSessionExpired = (): boolean => {
    const currentTime = new Date().getTime();
    return currentTime < Number(localStorage.getItem("expire_login"));
  };

  useEffect(() => {
    const storedStatus = localStorage.getItem("isAuthorized") as Authorized;
    setIsAuthorized(
      storedStatus && isSessionExpired() ? storedStatus : "unAuthorized"
    );
  }, []);

  useEffect(() => {
    if (isAuthorized === "unAuthorized") {
      navigate("/");
    } else if (
      isAuthorized === "Authorized" &&
      window.location.pathname === "/"
    ) {
      navigate("/tasks");
    }
  }, [isAuthorized, navigate]);

  return (
    <SessionContext.Provider
      value={{
        isAuthorized,
        setSession,
        user,
        loading,
        setUser,
        hasPermission,
        initUser,
        logOut,
        setLoading,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

// Custom hook to use the SessionStore
export const useSession = () => useContext(SessionContext);
