import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
// import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
// import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";

export interface MenuItems {
  icon: any;
  title: string;
}

export function getMenuItems(): MenuItems[] {
  return [
    // {
    //   icon: <AccountBalanceWalletOutlinedIcon />,
    //   title: "vyzvy",
    // },
    // {
    //   icon: <AssignmentOutlinedIcon />,
    //   title: "agenda",
    // },
    {
      icon: <TaskOutlinedIcon />,
      title: "tasks",
    },
    {
      icon: <AccountBalanceRoundedIcon />,
      title: "domains",
    },
  ];
}

export default getMenuItems;
