import React, { useCallback, useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";

interface NativeSelectProps {
  options: string[];
  name: string;
  changeSelectedValue: (value: number | string) => void;
  value: number | string;
  returnValue?: "number" | "string";
}

const numberCheck = (value: number | string): value is number =>
  typeof value === "number";

const NativeSelectComponent: React.FC<NativeSelectProps> = ({
  options,
  name,
  changeSelectedValue,
  value,
  returnValue,
}) => {
  useEffect(() => {
    if (options.some((o) => o === "")) {
      changeSelectedValue(
        returnValue === "number"
          ? options.length + 1
          : options[options.length - 1]
      );
    }
  }, [options]);

  // Determine the selected value
  const selectedValue = useMemo(() => {
    return numberCheck(value) && returnValue === "number"
      ? options[value - 1]
      : value;
  }, [value, returnValue]);

  // Handle change event
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const newValue = event.target.value;
      changeSelectedValue(
        returnValue === "number" ? options.indexOf(newValue) + 1 : newValue
      );
    },
    [changeSelectedValue, options, returnValue]
  );

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <NativeSelect
          value={selectedValue ?? ""}
          inputProps={{
            name: name,
            id: `native_${name}`,
          }}
          onChange={handleChange}
          sx={{
            fontSize: "12px",
          }}
        >
          <option key="" value="" disabled>
            Choose an Option
          </option>
          {options
            ?.map((o: any) => o.type || o.platform || o)
            .map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
        </NativeSelect>
      </FormControl>
    </Box>
  );
};

export default NativeSelectComponent;
