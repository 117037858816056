import { ChangeEvent } from "react";
import {
  ChangeFormValueCallback,
  ComplexState,
  FormEdit,
  FormField,
  FormSelectedValue,
  TemplateType,
} from "../../../models/Form";
import { NoneTypedObject } from "../../../models_objects/Form-o";
import { templateMapping } from "../../../models_objects/Form-template-o";
import { DirigentTable } from "../../../models/Table";
import { enqueueSnackbar } from "notistack";

const checkConditionSetSelectedValueEvent = (
  formSelectedValue: FormSelectedValue
) => {
  return formSelectedValue.multi || formSelectedValue.type === "";
};

const getRequestData = (tableStore: any, rowState: any) => {
  return !!tableStore.dirigentTableState?.singleSelectedField
    ? {
        ...getFilteredContact(tableStore),
        [tableStore.dirigentTableState.singleSelectedField || ""]: [rowState],
      }
    : rowState;
};

const changeSelectedValueState = (
  setSelectedValue: React.Dispatch<any>,
  formSelectedValue: FormSelectedValue
) => {
  setSelectedValue((prevState: ComplexState) => {
    return {
      ...prevState,
      selectedValue: [
        ...prevState.selectedValue.filter(
          (o: any) => o.fieldName !== formSelectedValue.fieldName
        ),
        formSelectedValue,
      ],
    };
  });
};

const getFilteredContact = (tableStore: any) => {
  const contact = tableStore.dirigentTableState.contact;
  const contactWithoutArraysEntries = Object.entries(contact).filter(
    ([key, value]) => !Array.isArray(value)
  );
  return Object.fromEntries(contactWithoutArraysEntries);
};

const getSelectedElement = (item: any, field: any, selectedValue: any) => {
  const dynamicKey = Object.keys(item).find(
    (key) => !["id", "type", "contact_id"].includes(key)
  );
  const selectedObjectOptions = findSelectedField(field.name, selectedValue);

  return dynamicKey
    ? {
        id: item.id,
        type: item.type,
        contact_id: item.contact_id,
        dynamicAttribute: item[dynamicKey],
        index: selectedObjectOptions.indexObject,
        open: selectedObjectOptions?.open,
      }
    : null;
};

const initialEditState = (template: { fields: FormField[] } | {}): FormEdit => {
  return hasFields(template)
    ? template.fields.reduce((formState: FormEdit, field) => {
        formState[field.name] = field.editable;
        return formState;
      }, {})
    : {};
};

const handleEdit = (
  setState: React.Dispatch<any>,
  name?: string,
  toggleAll?: boolean
): void => {
  setState((prevState: any) => {
    if (toggleAll) {
      const newFormEdit = Object.keys(prevState.formEdit).reduce((acc, key) => {
        acc[key] = !prevState.formEdit[key];
        return acc;
      }, {} as { [key: string]: boolean });
      return {
        ...prevState,
        formEdit: newFormEdit,
      };
    }

    return {
      ...prevState,
      formEdit: {
        ...prevState.formEdit,
        [name!]: !prevState.formEdit[name!],
      },
    };
  });
};

const showInputs = (setState: React.Dispatch<any>, index: number): void => {
  setState((prevStates: any) => ({
    ...prevStates,
    isActive: {
      ...prevStates.isActive,
      [index]: {
        active: !prevStates.isActive[index]?.active || false,
      },
    },
  }));
};

const findSelectedField = (fieldName: string, selectedValue: any) => {
  return (
    selectedValue?.find((item: any) => item.fieldName === fieldName) ??
    selectedValue[0]
  );
};

function hasFields(template: any): template is { fields: FormField[] } {
  return template && Array.isArray(template.fields);
}

async function getTemplate(
  type: string
): Promise<{ fields: FormField[] } | null> {
  if (!(type in templateMapping)) {
    enqueueSnackbar(`${type.toUpperCase()} Template Form is missing 🥲`, {
      variant: "error",
    });
    return Promise.resolve(null);
  }

  return templateMapping[type as TemplateType]();
}

function createSingleValueFormObject(subField: any): string {
  return subField.dynamicAttribute;
}

const isSelectedInputButton = (isActive: any, index: number) => {
  return !isActive?.active && index !== 0;
};

const getTypedCSSDeleteButton = (
  tableState: ComplexState,
  fieldName: string,
  indexTemplate: number,
  index: number
) => {
  return `${tableState.formEdit[fieldName] ? "selected-button" : ""}  ${
    !tableState.isActive[indexTemplate]?.active && index !== 0
      ? "none-visible"
      : ""
  }`;
};

const isTypedSelect = (field: any) => {
  return field.type === "select" && NoneTypedObject.includes(field.name);
};

const isUnTypedSelect = (tableState: ComplexState, field: any) => {
  return (
    field.type === "select" &&
    !!tableState.rowState[field.name] &&
    !NoneTypedObject.includes(field.name)
  );
};

const changeStateBasicElementForm = (
  fieldName: string,
  setRow: React.Dispatch<any>,
  selectValue: number | string
) => {
  setRow((state: any) => {
    return {
      ...state,
      rowState: {
        ...state.rowState,
        [fieldName]: selectValue,
      },
    };
  });
};

const changeFormState = async (
  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  subItemName: string,
  setRow: React.Dispatch<any>,
  entityRelationshipId?: number,
  imageUrl?: string,
  callback?: ChangeFormValueCallback
): Promise<void> => {
  const target = event.target as HTMLInputElement | HTMLTextAreaElement;
  const { id, name } = target;
  let value = "files" in target && target.files ? imageUrl || "" : target.value;

  setRow((state: any) => {
    let currentArray;
    if (
      state.rowState?.[name] &&
      Array.isArray(state.rowState[name]) &&
      !!subItemName
    ) {
      currentArray = [...state.rowState[name]];
      currentArray[parseInt(id)] = {
        ...(!!currentArray[parseInt(id)]
          ? currentArray[parseInt(id)]
          : {
              contact_id: entityRelationshipId,
            }),
        [subItemName]: value,
      };
    }
    const newState = {
      ...state,
      rowState: {
        ...state.rowState,
        [name]: currentArray || value,
      },
    };

    if (callback) callback(newState);
    return newState;
  });
};

const removeForm = (
  entityName: string,
  setTableStore: React.Dispatch<React.SetStateAction<DirigentTable>>,
  clearAll?: boolean
) => {
  setTableStore((store: DirigentTable) => {
    return {
      ...store,
      sidePanelItems: clearAll
        ? []
        : store.sidePanelItems
            ?.filter((f) => f.componentProps.entityName !== entityName)
            .map((o) =>
              o.componentProps.entityName === "contacts"
                ? getUpdatedFormRow(o, store)
                : o
            ),
    };
  });
};

const getUpdatedFormRow = (o: any, store: DirigentTable) => {
  return {
    ...o,
    componentProps: {
      ...o.componentProps,
      row: store.rows.filter((r) => r.id === store.contact.id)[0],
    },
  };
};

export const getFormattedDate = (fieldValue: string) => {
  const date = new Date(fieldValue);
  if (isNaN(date.getTime())) {
    return "";
  }
  return date.toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  });
};

export {
  changeFormState,
  checkConditionSetSelectedValueEvent,
  changeSelectedValueState,
  getRequestData,
  getSelectedElement,
  hasFields,
  createSingleValueFormObject,
  getTemplate,
  isSelectedInputButton,
  getTypedCSSDeleteButton,
  isTypedSelect,
  isUnTypedSelect,
  initialEditState,
  handleEdit,
  showInputs,
  removeForm,
  changeStateBasicElementForm,
};
