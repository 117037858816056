import React, { createContext, useState, useContext, ReactNode } from "react";
import { MagicContent, MagicContextType } from "../models/MagicHook";

const MagicContext = createContext<MagicContextType | undefined>(undefined);

export const MagicProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [magicContent, setMagicContent] = useState<MagicContent[]>([]);

  return (
    <MagicContext.Provider value={{ magicContent, setMagicContent }}>
      {children}
    </MagicContext.Provider>
  );
};

export const useMagicManipulatorStore = () => {
  const context = useContext(MagicContext);
  if (context === undefined) {
    throw new Error("useMagicManipulator must be used within a MagicProvider");
  }
  return context;
};
