import React, { createContext, useContext, useState } from "react";
import {
  DashboardState,
  DirigentTable,
  TableProviderActions,
} from "../models/Table";
import {
  DashboardCreateUpdateStateChange,
  DeleteFormRequest,
} from "../models/Form";
import { SidePanelComponentsEvent } from "../models/Domains-site";
import FormComponent from "../components/Parts_Form/Form/Form-component";

// Initial state
const initialDirigentTableState: DirigentTable = {
  logoUrl: "",
  id: undefined,
  tableName: "",
  selectedTable: "",
  columns: [],
  rows: [],
  singleSelectedField: "",
  contact: {},
  sidePanelItems: [],
};

// Context
const DirigentTableContext = createContext<TableProviderActions>({
  tableStore: initialDirigentTableState,
  setTableStore: () => {},
  getColumnData: (column_name: string) => [],
  addSidePanelItem: (component: any, componentProps: any) => {},
  setTableBasicAttribute: (key: string, value: any) => {},
  changeContactDeleteState: (
    params: DeleteFormRequest,
    dashboard?: DashboardState
  ) => {},
  inserOrRemoveFormComponents: (
    sidePanelComEvent: SidePanelComponentsEvent
  ) => {},
  changeDashboardCreateState: (
    dashboardState: DashboardCreateUpdateStateChange
  ) => {},
  changeDashboardCreateStateSingle: (newRecord: any, tableName?: string) => {},
  addNewRecordForBasicEntityState: (rowState: any) => {},
  changeCreateContactState: (
    setTableStore: React.Dispatch<React.SetStateAction<DirigentTable>>,
    rowState: any,
    createRow?: boolean
  ) => {},
  updateDashboardUpdateState: (
    dashboardState: DashboardCreateUpdateStateChange
  ) => {},
  changeControllerDeleteState: (
    params: DeleteFormRequest,
    dashboard?: DashboardState
  ) => {},
});

// provider
export const DirigentTableProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [tableStore, setTableStore] = useState<DirigentTable>(
    initialDirigentTableState
  );

  const getColumnData = (column_name: string) => {
    return tableStore.rows.map((r) => r[column_name]);
  };

  const addSidePanelItem = (component: any, componentProps: any) => {
    setTableStore((state) => ({
      ...state,
      sidePanelItems: [
        {
          component,
          componentProps,
        },
      ],
    }));
  };

  const setTableBasicAttribute = (key: string, value: any) => {
    setTableStore((state) => ({
      ...state,
      [key]: value,
    }));
  };

  const changeContactDeleteState = (
    params: DeleteFormRequest,
    dashboard?: DashboardState
  ) => {
    const { id, fieldName, setTableStore, contact_id } = params;
    if (dashboard) {
      changeDashBoardDelete(dashboard, fieldName, id);
    } else {
      setTableStore((prevState) => ({
        ...prevState,
        rows:
          fieldName === "Contacts"
            ? prevState.rows.filter((r) => r.id !== id)
            : prevState.rows.map((r) => {
                return r.id === contact_id
                  ? {
                      ...r,
                      [fieldName]: r[fieldName].filter((s: any) => s.id !== id),
                    }
                  : r;
              }),
      }));
    }
  };

  const changeControllerDeleteState = (
    params: DeleteFormRequest,
    dashboard?: DashboardState
  ) => {
    const { id, fieldName, setTableStore } = params;
    if (dashboard) {
      changeDashBoardDelete(dashboard, fieldName, id);
    } else {
      setTableStore((state) => ({
        ...state,
        rows: state.rows.filter((r) => r.id !== id),
      }));
    }
  };

  const changeDashBoardDelete = (
    dashboard: DashboardState,
    fieldName: string,
    id: number
  ) => {
    dashboard.setTables((prevState) =>
      prevState.map((table) =>
        table.tableName === fieldName
          ? { ...table, rows: table.rows.filter((r) => r.id !== id) }
          : table
      )
    );
  };

  const inserOrRemoveFormComponents = (
    sidePanelComEvent: SidePanelComponentsEvent
  ) => {
    sidePanelComEvent.setAddForms((state: DirigentTable) => ({
      ...state,
      sidePanelItems:
        sidePanelComEvent.isEvent && sidePanelComEvent.subForm
          ? [
              {
                component: FormComponent,
                componentProps: {
                  ...sidePanelComEvent.formProps,
                  ...sidePanelComEvent.formHandlers,
                },
              },
              ...state.sidePanelItems,
            ]
          : [
              {
                component: FormComponent,
                componentProps: {
                  ...sidePanelComEvent.formProps,
                  ...sidePanelComEvent.formHandlers,
                },
              },
            ],
    }));
  };

  const changeDashboardCreateState = (
    dashboardState: DashboardCreateUpdateStateChange
  ) => {
    const { setTables, tableName, res, rowState } = dashboardState;
    setTables((tables) =>
      tables.map((t) =>
        t.tableName === tableName
          ? {
              ...t,
              rows: [
                ...t.rows,
                {
                  id: res?.data[0],
                  ...rowState.values,
                },
              ],
            }
          : t
      )
    );
  };

  const updateDashboardUpdateState = (
    dashboardState: DashboardCreateUpdateStateChange
  ) => {
    const { setTables, tableName, rowState } = dashboardState;
    setTables((tables) =>
      tables.map((t) =>
        t.tableName === tableName
          ? {
              ...t,
              rows: [
                ...t.rows.filter((o) => o.id !== rowState.values.id),
                {
                  ...rowState.values,
                },
              ],
            }
          : t
      )
    );
  };

  const changeDashboardCreateStateSingle = (
    newRecord: any,
    tableName?: string
  ) => {
    setTableStore((prevState) => {
      const selectedRow = prevState.rows.filter(
        (o) => o.id === tableStore.id
      )[0];

      const updateSelectedRow = {
        ...selectedRow,
        [tableName || ""]: [
          ...selectedRow[tableName || ""].filter((r: any) => !!r.id),
          newRecord,
        ],
      };

      return {
        ...prevState,
        rows: selectedRow
          ? prevState.rows.map((o) =>
              o.id === updateSelectedRow.id ? updateSelectedRow : o
            )
          : prevState.rows,
      };
    });
  };

  const addNewRecordForBasicEntityState = (rowState: any) => {
    setTableStore((state) => ({
      ...state,
      rows: [...state.rows, rowState],
    }));
  };

  const changeCreateContactState = (rowState: any, createRow?: boolean) => {
    setTableStore((prevState) => ({
      ...prevState,
      rows: createRow
        ? [...prevState.rows, rowState]
        : prevState.rows.map((row) => {
            return row.id === rowState.id ? { ...row, ...rowState } : row;
          }),
    }));
  };

  return (
    <DirigentTableContext.Provider
      value={{
        tableStore,
        setTableStore,
        getColumnData,
        addSidePanelItem,
        setTableBasicAttribute,
        changeContactDeleteState,
        inserOrRemoveFormComponents,
        changeDashboardCreateState,
        changeDashboardCreateStateSingle,
        addNewRecordForBasicEntityState,
        changeCreateContactState,
        updateDashboardUpdateState,
        changeControllerDeleteState,
      }}
    >
      {children}
    </DirigentTableContext.Provider>
  );
};

// Custom hook to use the dirigent table store
export const useDirigentTableStore = () => {
  const context = useContext(DirigentTableContext);
  if (!context) {
    throw new Error(
      "useDirigentTableStore must be used within a DirigentTableProvider"
    );
  }
  return context;
};
