import { Select, Theme } from "@mui/material";
import styled from "styled-components";
import AppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  theme: Theme;
}

interface NavigationProps {
  theme_mode: "light" | "dark";
  isMobile?: boolean;
}

export const Navigation = styled.nav<NavigationProps>`
  width: 100%;
  height: 12%;
  display: flex;
  justify-content: space-between;
  align-items: end;
  ${({ theme_mode }) => (theme_mode === "dark" ? "box-shadow: none;" : "")}
  background-color: ${({ theme_mode }) =>
    theme_mode === "dark" ? "var(--dark-mode-custom-background)" : "#f4f5f6"};

  ul {
    margin: 0;
    padding: 0;
    display: inherit;
    height: 100%;
    padding-left: 1em;
  }
  .personal-wrapper {
    display: inherit;
    margin-right: 1em;
    > * {
      margin-right: 0.5em;
    }
  }
`;

export const AppBarStyled = styled(AppBar)<AppBarProps>`
  transition: ${({ theme }) =>
    theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })};
  ${({ theme, open }) =>
    open &&
    `
    margin-left: 100px;
    transition: ${theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    })};
  `}
  background-color: ${({ theme }) =>
    (theme.palette.mode === "dark"
      ? "var(--dark-mode-custom-background)"
      : "#f4f5f6") + "!important"};
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
`;

export const DirigentSystems = styled(Select)`
  height: 2em;
  margin-right: 1em;
  font-size: small;
  margin-bottom: 6px;
  .earth-picture {
    margin-right: 4px;
    align-self: center;
  }
  &.Mui-focused .MuiOutlinedInput-root {
    outline: none;
    box-shadow: none;
  }
`;

export const ChangeSystem = styled.div<NavigationProps>`
  display: flex;
  background-color: transparent;
  color: #38b45c;
  border: none;
  padding: 0;
  padding-right: 2px;
  margin-right: 8px;
  p {
    color: ${({ theme_mode }) =>
      theme_mode === "dark" ? "var(--dark-mode-text)" : "#4697ff"};
    margin: 0;
    font-size: small;
  }
`;

export const ListItemStyled = styled.li<
  NavigationProps & { selected_item: string }
>`
  display: flex;
  margin-top: ${({ isMobile }) => (isMobile ? "0.5em" : "")};
  align-items: center;
  padding: 0.6em 8px;
  border-top-right-radius: 0.5em;
  border-top-left-radius: 0.5em;
  background-color: ${({ theme_mode, selected_item }) =>
    selected_item === "true"
      ? getListItemBackgroundByTheme(theme_mode)
      : "transparent"};
  .nav-button {
    font-size: large;
  }

  > svg {
    color: ${({ selected_item, theme_mode }) =>
      selected_item === "true"
        ? "#4697ff;"
        : getSvgIconBackgroundByTheme(theme_mode)};
    font-size: large;
  }
  .clicked {
  }
  p {
    padding: 0px 2px 0px 4px;
    margin: 0;
    color: ${({ selected_item, theme_mode, isMobile }) =>
      isMobile ? getSelectedItemColor(selected_item, theme_mode) : "#4697ff;"};
    font-size: small;
  }
`;

const getSelectedItemColor = (
  selected_item: string,
  theme_mode: "light" | "dark"
) => {
  return selected_item === "true"
    ? "#4697ff;"
    : getSvgIconBackgroundByTheme(theme_mode);
};

const getListItemBackgroundByTheme = (theme_mode: "light" | "dark"): string => {
  return theme_mode === "light" ? "white" : "var(--dark-mode-background)";
};

const getSvgIconBackgroundByTheme = (theme_mode: "light" | "dark"): string => {
  return theme_mode === "dark" ? "var(--dark-mode-text)" : "";
};
