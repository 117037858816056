import { getTemplate } from "../components/Parts_Form/Form/Form-facade-service";
import { FormSelectedValue } from "../models/Form";

export const formStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "2px solid #000",
  boxshadow: 24,
  p: 4,
};

export const basicTypes = ["int", "text", "date", "timestamp", "password"];
export const NoneTypedObject = ["notes"];
export const returnNumberInSelect = ["User_meta_keys"];

export let formSelectedValues: FormSelectedValue[] = [
  { fieldName: "emails", type: "" },
  { fieldName: "websites", type: "" },
  { fieldName: "addresses", type: "", open: false },
  { fieldName: "phone_numbers", type: "" },
  { fieldName: "social_profiles", type: "" },
];

export const deleteDialog = {
  title: "Confirm delete",
  description: "Are you sure you want to delete",
};

export const getChangeFormMethodParameter = async (
  rowState: any,
  formSelectedValue: FormSelectedValue
) => {
  return {
    formProps: {
      selectedRow: rowState || "",
      formTemplate: await getTemplate(formSelectedValue.fieldName),
      tableName: formSelectedValue.fieldName,
    },
    open: true,
    subForm: true,
  };
};

export const getFormProps = (
  isEvent: boolean,
  formEventData: any,
  subForm?: boolean
) => {
  return isEvent
    ? {
        row: formEventData.selectedRow,
        template: formEventData.formTemplate,
        entityName: formEventData.tableName,
        ...(subForm && { toggleAll: true }),
      }
    : {
        row: formEventData.row,
        template: formEventData.template,
        entityName: formEventData.entityName,
      };
};
