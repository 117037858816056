import { IconButton } from "@mui/material";
import styled from "styled-components";
import iconButtonStyled from "@emotion/styled";

interface FormSyledProps {
  $isLight: boolean;
}

export const Wrapper = styled.div<FormSyledProps>`
  border: none;
  border-radius: 0.5em;
  width: 95%;
  padding: 10px;
  background-color: ${({ $isLight }) =>
    $isLight ? "white" : "var(--dark-mode-custom-background)"};
`;

export const RowContainer = styled.div<
  FormSyledProps & {
    $isNotes: boolean;
    $isSelect: boolean;
    $isPhoto: boolean;
    $isTextArea: boolean;
    $space?: number;
  }
>`
  display: inherit;
  ${({ $isTextArea }) => ($isTextArea ? "flex-direction: column;" : "")};
  margin-top: ${({ $space }) => ($space ? `${$space}` : "0.2")}em;
  padding-left: 4px;
  padding-top: 4px;
  #company {
    margin-right: 3.6em;
  }
  #emails,
  #phonenumbers {
    margin-right: 0;
  }
  &:hover {
    background-color: ${({ $isLight, $isPhoto }) =>
      !$isPhoto ? ($isLight ? "whitesmoke" : "#3a3c3f") : ""};
    border-radius: 0.2em;
  }
  &:hover input {
    background-color: ${({ $isLight }) =>
      $isLight ? "whitesmoke" : "#3a3c3f"};
  }
  margin-top: ${({ $isNotes }) => ($isNotes ? "24px" : "")};
  margin-bottom: ${({ $isSelect }) => ($isSelect ? "6px" : "")};
`;

export const FormLabelStyled = styled.label<FormSyledProps>`
  margin-right: auto;
  font-size: small;
  font-weight: 400;
  font-style: italic;
  &:hover {
    background-color: ${({ $isLight }) =>
      $isLight ? "whitesmoke" : "#3a3c3f"};
  }
  color: ${({ $isLight }) => ($isLight ? "black" : "white")};
`;

export const StyledInput = styled.input<
  FormSyledProps & { $isSelected?: boolean; $fullWidth?: boolean }
>`
  height: 24px;
  border-top: none;
  border-right: none;
  border-left: none;
  outline: none;
  color: white;
  background-color: var(--dark-mode-custom-background);
  font-size: x-small;
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "50%")};
  display: ${({ $isSelected }) => ($isSelected ? "none" : "block")};

  ${({ $isLight }) =>
    $isLight &&
    `
      background-color: white;
      color: black;
    `}
`;

export const IconButtonStyledNotes = iconButtonStyled(IconButton, {
  shouldForwardProp: (prop) => prop !== "$isSelected",
})<{ $isSelected?: boolean }>`
  display: ${({ $isSelected }) => ($isSelected ? "none" : "block")};
  margin-right: 2.1em;
  height: 28px;
`;

export const IconButtonStyled = styled(IconButton)<{ $isSelected?: boolean }>`
  margin-right: 2.1em;
  height: 28px;
`;
