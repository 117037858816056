import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import SwitchThemeComponent from "../../Switch_theme/SwitchTheme-component";
import SearchBarComponent from "../../SearchBar/SearchBar-component";
import { useDirigentTableStore } from "../../../providers/Table-provider";
import ProfileComponent from "../Profile/profile-component";
import MenuIcon from "@mui/icons-material/Menu";
import LinearProgress from "@mui/material/LinearProgress";
import {
  Box,
  Drawer,
  IconButton,
  Stack,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  AppBarStyled,
  ChangeSystem,
  DirigentSystems,
  ListItemStyled,
  Navigation,
} from "./Navigation-styled";
import { useSession } from "../../../providers/Authorized-provider";
import getMenuItems, { MenuItems } from "./Navigation-facade-service";

const NavigationComponent = () => {
  const [selected, setSelectedItem] = useState<null | string>(null);
  const [openMenu, setOpenMenu] = useState(false);
  const drawerRef = useRef<HTMLDivElement>(null);
  const menuItems = useRef<MenuItems[] | []>([]);

  const isMobile = useMediaQuery("(max-width:600px)");
  const { tableStore } = useDirigentTableStore();
  const navigate = useNavigate();
  const theme = useTheme();
  const { loading } = useSession();

  useEffect(() => {
    menuItems.current = getMenuItems();
    setSelectedByURL();
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const setSelectedByURL = () => {
    if (menuItems.current.some((o) => window.location.href.endsWith(o.title))) {
      setSelectedItem(
        menuItems.current.find((o) => window.location.href.endsWith(o.title))
          ?.title || null
      );
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      drawerRef.current &&
      !drawerRef.current.contains(event.target as Node)
    ) {
      setOpenMenu(false);
    }
  };

  const handleClick = (title: string) => {
    navigate(`/${title}`);
    if (isMobile) setOpenMenu(!openMenu);
    setSelectedItem(title);
  };

  return (
    <>
      <Navigation theme_mode={theme.palette.mode}>
        {isMobile && (
          <>
            <AppBarStyled position="relative" open={openMenu} theme={theme}>
              <Toolbar>
                <IconButton
                  onClick={setOpenMenu.bind({}, !openMenu)}
                  edge="start"
                  sx={[
                    {
                      mr: 2,
                    },
                    openMenu && { display: "none" },
                  ]}
                >
                  <MenuIcon />
                </IconButton>
              </Toolbar>
              <SearchBarComponent
                options={tableStore.rows} // Todo add search data
                placeholder="Search..."
                isMobile={isMobile}
              />
              <div className="personal-wrapper">
                <SwitchThemeComponent></SwitchThemeComponent>
                <ProfileComponent />
              </div>
            </AppBarStyled>
            <Drawer
              sx={{
                width: 200,
              }}
              variant="persistent"
              anchor="left"
              open={openMenu}
              ref={drawerRef}
            >
              <Box
                sx={{
                  width: "100%",
                }}
                role="presentation"
              >
                <ul>
                  {menuItems.current.map((item, index) => (
                    <ListItemStyled
                      key={index}
                      selected_item={(selected === item.title).toString()}
                      theme_mode={theme.palette.mode}
                      isMobile={isMobile}
                      onClick={() => handleClick(item.title)}
                    >
                      {item.icon}
                      <p>{item.title}</p>
                    </ListItemStyled>
                  ))}
                </ul>
              </Box>
            </Drawer>
          </>
        )}
        {!isMobile && (
          <>
            <ul>
              <DirigentSystems
                value="dirigent-option"
                renderValue={() => {
                  return (
                    <ChangeSystem theme_mode={theme.palette.mode}>
                      <LanguageOutlinedIcon className="earth-picture" />
                      <p>Smart Dirigent </p>
                    </ChangeSystem>
                  );
                }}
              ></DirigentSystems>
              {menuItems.current.map((item, index) => (
                <ListItemStyled
                  key={index}
                  selected_item={(selected === item.title).toString()}
                  theme_mode={theme.palette.mode}
                  onClick={() => handleClick(item.title)}
                >
                  {item.icon}
                  {selected === item.title && <p>{item.title}</p>}
                </ListItemStyled>
              ))}
            </ul>
            <SearchBarComponent
              options={tableStore.rows} // Todo add search data
              placeholder="Search..."
              isMobile={isMobile}
            />
            <div className="personal-wrapper">
              <SwitchThemeComponent></SwitchThemeComponent>
              <ProfileComponent />
            </div>
          </>
        )}
      </Navigation>
      {loading && (
        <Stack sx={{ width: "100%", color: "grey.500" }} spacing={0.2}>
          <LinearProgress color="secondary" />
        </Stack>
      )}
    </>
  );
};

export default NavigationComponent;
