import React, { createContext, useContext, useEffect, useState } from "react";
import { MobileView } from "../models/Mobile";

// Initial state
const initialMobileViewState: MobileView = {
  isMobile: false,
};

// context
const MobileViewContext = createContext<{
  mobileViewState: MobileView;
  setMobileViewState: React.Dispatch<React.SetStateAction<MobileView>>;
}>({
  mobileViewState: initialMobileViewState,
  setMobileViewState: () => {},
});

// provider
export const MobileViewProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [mobileViewState, setMobileViewState] = useState<MobileView>(
    initialMobileViewState
  );

  useEffect(() => {
    setMobileViewState({
      isMobile: window.innerWidth <= 600,
    });
  }, []);

  return (
    <MobileViewContext.Provider value={{ mobileViewState, setMobileViewState }}>
      {children}
    </MobileViewContext.Provider>
  );
};

// Custom hook to use the Mobile store
export const useMobileViewStore = () => {
  const context = useContext(MobileViewContext);
  if (!context) {
    throw new Error(
      "useDirigentTableStore must be used within a DirigentTableProvider"
    );
  }
  return context;
};
