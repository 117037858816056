import { FormField, TemplateType } from "../models/Form";

export const templateMapping: Record<
  TemplateType,
  () => Promise<{ fields: FormField[] }>
> = {
  addresses: () =>
    import("../templates/contact/Addresses_template_v1.json").then(
      (module) => module.default as { fields: FormField[] }
    ),
  social_profiles: () =>
    import("../templates/contact/SocialProfiles_template_v1.json").then(
      (module) => module.default as { fields: FormField[] }
    ),
  emails: () =>
    import("../templates/contact/Emails_template_v1.json").then(
      (module) => module.default as { fields: FormField[] }
    ),
  phone_numbers: () =>
    import("../templates/contact/Phone_temnplate_v1.json").then(
      (module) => module.default as { fields: FormField[] }
    ),
  websites: () =>
    import("../templates/contact/Websites_template_v1.json").then(
      (module) => module.default as { fields: FormField[] }
    ),
  contacts: () =>
    import("../templates/contact/Contacts_template_v3.json").then(
      (module) => module.default as { fields: FormField[] }
    ),
  notes: () =>
    import("../templates/contact/Notes_template_v1.json").then(
      (module) => module.default as { fields: FormField[] }
    ),
  export: () =>
    import("../templates/Export_template_v1.json").then(
      (module) => module.default as { fields: FormField[] }
    ),
  user_meta_keys: () =>
    import("../templates/user/User_meta_keys_template_v1.json").then(
      (module) => module.default as { fields: FormField[] }
    ),
  user: () =>
    import("../templates/user/User_template_v1.json").then(
      (module) => module.default as { fields: FormField[] }
    ),
  magicManipulation: () =>
    import("../templates/MagicManipulation_template_v1.json").then(
      (module) => module.default as { fields: FormField[] }
    ),
  tasks: () =>
    import("../templates/tasks/TaskManagment_template.json").then(
      (module) => module.default as { fields: FormField[] }
    ),
};
