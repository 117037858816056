import { IconButton, MenuItem, Select, useTheme } from "@mui/material";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import ColorModeContext from "../../providers/Theme-provider";
import React, { useState } from "react";
import "./SwitchTheme.scss";

const SwitchThemeComponent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const colorMode = React.useContext(ColorModeContext);
  const theme = useTheme();

  return (
    <Select
      open={isOpen}
      className="select-wrapper"
      onClose={() => setIsOpen(!isOpen)}
      onOpen={() => setIsOpen(!isOpen)}
      value={theme.palette.mode}
      onChange={colorMode.toggleColorMode}
      sx={{
        "& .MuiSelect-icon": {
          display: "none",
        },
        fontSize: 12,
      }}
      variant="standard"
    >
      <MenuItem value="light">
        <IconButton color="inherit">
          <Brightness4Icon />
        </IconButton>
        {isOpen && "Light mode"}
      </MenuItem>
      <MenuItem value="dark">
        <IconButton color="inherit">
          <Brightness7Icon />
        </IconButton>
        {isOpen && "Dark mode"}
      </MenuItem>
    </Select>
  );
};

export default SwitchThemeComponent;
