import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import BackupRoundedIcon from "@mui/icons-material/BackupRounded";
import CloudDownloadRoundedIcon from "@mui/icons-material/CloudDownloadRounded";
import ChangeCircleRoundedIcon from "@mui/icons-material/ChangeCircleRounded";
import {
  createDownloadLink,
  fetchBlob,
  getFileName,
  uploadPhoto,
} from "./Form-control-photo-facade-service";
import env from "../../../env.config";
import { IconButton } from "@mui/material";
import "./Form-control-photo.scss";

interface PhotoForm {
  id: number;
  photoUrlIn: any;
  savePhoto: (event: ChangeEvent<HTMLInputElement>, imageUrl: string) => void;
}

const FormControlPhotoComponent: React.FC<PhotoForm> = ({
  id,
  photoUrlIn,
  savePhoto,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [photoUrl, setPhotoUrl] = useState("");

  useEffect(() => {
    setPhotoUrl(photoUrlIn);
  }, [photoUrlIn]);

  const handleUpload = useCallback(
    async (event: any) => {
      const file: File | null = inputRef.current?.files?.[0] || null;
      if (file) {
        const fileURL = URL.createObjectURL(file);
        const formData = new FormData();
        formData.append("image", file);
        uploadPhoto({
          formData,
          photoUrl: getFileName(photoUrl),
          setPhotoUrl: () => setPhotoUrl(fileURL),
          savePhoto: (imageUrl: string) => savePhoto(event, imageUrl),
        });
      }
    },
    [photoUrl, savePhoto]
  );

  const handleDownload = useCallback(async () => {
    try {
      const filename = getFileName(photoUrl) || "downloaded-file.jpg";
      const apiUrl = `${env.apiUrl}contact/download/${filename}`;
      const blob = await fetchBlob(apiUrl);
      const objectURL = window.URL.createObjectURL(blob);
      createDownloadLink(objectURL, filename);
    } catch (error) {
      console.error("Download failed:", error);
    }
  }, [photoUrl]);

  return (
    <div
      style={{
        textAlign: "center",
      }}
    >
      {photoUrl ? (
        <>
          <img
            src={photoUrl}
            alt="Review"
            style={{
              maxHeight: "10vh",
            }}
          />
          <div className="action-container">
            <IconButton className="download-event" onClick={handleDownload}>
              <CloudDownloadRoundedIcon />
              &nbsp;
              <span>Download</span>
            </IconButton>
            <IconButton
              className="change-event"
              onClick={() => inputRef.current?.click()}
            >
              <ChangeCircleRoundedIcon />
              &nbsp;
              <span>Change</span>
            </IconButton>
          </div>
        </>
      ) : (
        <>
          <IconButton
            className="upload-event"
            onClick={() => inputRef.current?.click()}
          >
            <BackupRoundedIcon id="uploadBtn" style={{ cursor: "pointer" }} />
            &nbsp;
            <label htmlFor="uploadBtn">Upload Photo</label>
          </IconButton>
        </>
      )}
      <input
        id={`${id}`}
        ref={inputRef}
        type="file"
        name="photo_url"
        style={{ display: "none" }}
        onChange={handleUpload}
      />
    </div>
  );
};

export default FormControlPhotoComponent;
