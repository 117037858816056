import React, { useEffect, useRef, useState } from "react";
import {
  AreaContainer,
  Characters,
  CharEmojiButton,
  Description,
  EmojiButton,
} from "./EmojiTextAre";
import { useMediaQuery, useTheme } from "@mui/material";
import textAreaEmojis from "../../../templates/tasks/TextArea_Emojis.json";

interface TextAreaComponentProps {
  fieldValue: string;
  setField: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  name: string;
}

const EmojiAreaComponent: React.FC<TextAreaComponentProps> = ({
  fieldValue,
  setField,
  name,
}) => {
  const [customFieldValue, setCustomField] = useState<string>("");
  const descriptionRef = useRef<HTMLTextAreaElement>(null);
  const cursorPositionRef = useRef<number | null>(null);

  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    setCustomField(fieldValue);
  }, [fieldValue]);

  useEffect(() => {
    if (descriptionRef.current && cursorPositionRef.current !== null) {
      descriptionRef.current.setSelectionRange(
        cursorPositionRef.current,
        cursorPositionRef.current
      );
    }
  }, [customFieldValue]);

  const addEmoji =
    (emoji: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setCustomField((prevValue) => `${prevValue} ${emoji} `);
      descriptionRef.current?.focus();
    };

  const changeFieldValue = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    setCustomField(newValue);
    cursorPositionRef.current = event.target.selectionStart;
    setField(event);
  };

  return (
    <AreaContainer $isDarkMode={theme.palette.mode === "dark"}>
      <div>
        {textAreaEmojis.map(({ id, char, type }) => {
          return type === "emoji" ? (
            <EmojiButton key={id} onClick={addEmoji(char)}>
              {char}
            </EmojiButton>
          ) : (
            <CharEmojiButton
              key={id}
              $isDarkMode={theme.palette.mode === "dark"}
              onClick={addEmoji(char)}
            >
              {char}
            </CharEmojiButton>
          );
        })}
      </div>
      <Description
        ref={descriptionRef}
        $isDarkMode={theme.palette.mode === "dark"}
        $isMobile={isMobile}
        placeholder="Type here…"
        value={customFieldValue}
        onChange={(event) => changeFieldValue(event)}
        name={name}
        rows={3}
      />
      <Characters $isDarkMode={theme.palette.mode === "dark"}>
        {customFieldValue.length} character(s)
      </Characters>
    </AreaContainer>
  );
};

export default EmojiAreaComponent;
