import React, { lazy, Suspense } from "react";
import NavigationComponent from "./components/Parts_Navigation/Navigation/Navigation-component";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import "./App.scss";
import { createTheme, useTheme } from "@mui/material";
import ColorModeContext from "./providers/Theme-provider";
import { DirigentTableProvider } from "./providers/Table-provider";
import { MobileViewProvider } from "./providers/Mobile-provider";
import { SessionProvider } from "./providers/Authorized-provider";
import { SnackbarProvider } from "notistack";
import { MagicProvider } from "./providers/MagicContext-provider";

const DomainSite = lazy(() => import("./sites/Domains/Domains-site"));
const AuthSite = lazy(
  () => import("./sites/Authentification/Authentification-site")
);
const TaskManagmentSite = lazy(
  () => import("./sites/TasksManagment/TaskManagment-site")
);

function MainApp() {
  const location = useLocation();
  const theme = useTheme();

  return (
    <>
      {location.pathname !== "/" && <NavigationComponent />}
      <div
        className={`content ${
          theme.palette.mode === "dark" ? "dark-mode-content" : ""
        }`}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<AuthSite />} />
            <Route path="/domains" element={<DomainSite />} />
            <Route path="/tasks" element={<TaskManagmentSite />} />
          </Routes>
        </Suspense>
      </div>
    </>
  );
}

function App() {
  const [mode, setMode] = React.useState<"light" | "dark">("dark");

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode]
  );

  return (
    <MobileViewProvider>
      <DirigentTableProvider>
        <MagicProvider>
          <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
              <BrowserRouter basename="/">
                <SessionProvider>
                  <SnackbarProvider maxSnack={3}>
                    <MainApp />
                  </SnackbarProvider>
                </SessionProvider>
              </BrowserRouter>
            </ThemeProvider>
          </ColorModeContext.Provider>
        </MagicProvider>
      </DirigentTableProvider>
    </MobileViewProvider>
  );
}

export default App;
